.inputSummary{
    background-color: $heibaInputBackground; 
    padding-left: 0.5em;
    width: 100%;
    padding-bottom: 2em;
    margin: auto;
    border-style: solid;
    border-width: 1px;
    border-color: gray;
    border-radius: $heibaBorderRadiusInput;
    min-height: 50vh;
    margin-top: 5em;
}
.inputSummary p:first-child{
    margin-top: 1.5em;
}
.summaryInput{
    font-weight: bold;
}
.summaryInputList{
    list-style: none;
    padding-left: 0px;
}
.SummaryPage h3{
    margin: .5em;
    margin-bottom: 0em;

}
.SummaryPage p{
    padding-left: 1em;
    padding-right: 1em;
    
}
.inputSummaryLabel{
    color: $heibaBlackOfficial;
    font-weight: 500;
    font-size: 14px;
    margin-left: 5px;
    font-weight: 700;
}
.inputSummaryStep{
    color: $btnBackOrange;
    font-weight: 600;
    margin-bottom: 2px;
}
.summaryInput{
    color: $heibaGray;
    margin-left: 20px;
}
.step{
    float: left;
    color: $heibaGray; /* donker grijs */
}
.stepNumber{
    float: right;
    color: $btnBackOrange;
    
}