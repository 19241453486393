@import "~bootstrap/scss/bootstrap";
@import './style/basics';
@import './style/Comments';
@import './style/CompanyInfo';
@import './style/Download';
@import './style/Dropzone';
@import './style/Footer';
@import './style/InputNavigation';
@import './style/InputSummary';
@import './style/Menu';
@import './style/Oops';
@import './style/Overlay';
@import './style/Preview';
@import './style/ProductFocus';
@import './style/Season';
@import './style/SelectWeek';
@import './style/SendButtons';
@import './style/Support';
@import './style/Top';
@import './style/TopicLine';
@import './style/VideoCard';
@import './style/admin';
@import './style/dayPicker';
@import './style/InputEnough';
@import './style/overlayImage';
@import './style/table';
@import './style/DisplayError';
// @import 'react-dropzone-uploader/dist/styles.css';
@import './style/playerSearch';
@import './style/profilePage';
@import './style/Office';
@import './style/InputTypeSelector';
@import './style/InputBanner';
@import './style/Input';
@import './style/InputResponsive';
@import './style/GeneralMessage';

.App {
  text-align: center;
  font: 100%/1.5em 'PT Sans', sans-serif;
  font-weight: normal;
  font-size: 100%;
  line-height: 1.5em;
}

#content-wrap {
  padding-top: 10em;
  padding-bottom: 4.5rem;    
  width: 95%;
  margin: auto;
  max-width: 1400px;
}
.generalMessage{
  padding-top: 14em !important;
}

@media only screen and (max-width: 600px){
  #content-wrap {
    padding-top: 9.5em;
  }
  .generalMessage{
    padding-top: 17.5em !important;
  }
}
#root{
  background-color: $heibaDarkBackground;
  position: relative;
  min-height: 100vh;
}