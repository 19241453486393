.videoHeading {
  text-align: center;
  color: $heibaWhite;
  font-size: 1em;
}
.videoHeading em {
  color: $btnBackOrange;
}
@media only screen and (min-width: 900px) {
  .previewComments {
    display: flex;
  }
}
.previewNewInput em{
  color: $heibaWhite;
  background-color: $btnBackOrange;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 5px;
  padding-right: 5px;
  height: 9vw;
  border-radius: .5em;
  font-weight: bold;
  color: $heibaWhite;
  text-decoration: none;
  font-weight: bold;
  text-transform: uppercase;
  max-height: 35px;
}
.previewNewInput em:hover{
  background-color: $heibaDarkGreen;
}

.previewNewInputHolder p{
  color: $heibaWhite;
  font-weight: bold;
}
.previewNewInputHolder{
  display: flex;
  justify-content: flex-end;
  margin-top: 2em;
  margin-bottom: 2em;
  cursor: default;
}


