.companyInfo{
    /* border-style: solid; */
    overflow: hidden;
    margin: auto;
    max-width: 1400px;
    padding: 10px;
    display: flex;
    padding-top: 0px;
    padding-bottom: 0px;
}
.companyInfoLogoHolder{
    width: 5em;
    height: 4em;
    float: left;
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 1em;
    background-position: center
}
@media only screen and (max-width: 600px) {
    .welcome{
        display: none;
    }
    .weekNumber p {
        font-size: .8em;
    }
    .companyInfoLogoHolder{
        margin-top: 0em;
    }
}

.companyInfoData{
    overflow: hidden;
    align-items: center;
    display: flex;
    justify-content: center;
}
.companyInfoData p {
    margin-left: 1em;
}
.username{
    color: $btnBackOrange;
    font-weight: bold;
}
.welcome{
    color: $heibaWhite; 
    font-weight: bold;
}
.company{
    color: $heibaBlueOffical;   
    font-weight: bold;
}
.welcomeBox em{
    line-height: 1.5em;
}
.welcomeBox p{
    font-weight: bold;
    margin-bottom: 0.5em;
}
.currentWeek{
    flex: auto;
    justify-content: flex-end;
    display: flex;
    align-items: center;
}
.weekNumber{
    
    width: 4.5em;
    margin-right: 1em;
}.weekNumber p{
    color: $heibaWhite;   
    font-weight: bold;
    margin-bottom: 0.5em;
}
.week{
    color: $heibaBlue; 
    font-size: 2em;
    text-align: end;
}
.weekNumber .week{
    font-size: 1.4em;
    color: $heibaBlueOffical; 
}