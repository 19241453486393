.officeItem {
    width: 20em;
    border-style: solid;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border-radius: $heibaBorderRadius;
    border-color: $heibaBlue;
    border-width: $heibaBorderWidth;
    margin: 1em;
    cursor: pointer;
}
.officeItem.double {
    width: 46em;
    padding: 20px;
}
.copyLink{
    color: $heibaBlue
}
.officeSpecificWeekInput{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    margin: 5px;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
}
.officeButtonHolder{
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin: 5px;
    align-items: flex-end;
    width: 100%;
}
.ui.form textarea{
    margin: 1em 0em 1em 0em; 
    background-color: $heibaInputBackground;
}
.officeContentInput{
    margin: 1em 0em 1em 0em; 
    background-color: $heibaInputBackground;
    color: black;
    padding: 5px;
    border-radius: 0.375rem;
}
em.name{
    color: $btnBackOrange;
    text-transform: capitalize;
}

.ui.table thead {
    position: sticky;
    position: -webkit-sticky;
    top: -1em;
    z-index: 1;
}
.ui.segment{
    background-color: $heibaDarkBackground;
}