.footer {
  background-color: $heibaGray;
  color: $heibaWhite;
  text-align: center;

  position: absolute;
  bottom: 0;
  width: 100%;
  height: 3.2rem;
  display: flex;
  justify-content: space-between;
}
.footer p {
  font-size: 0.5rem;
  margin: auto;
  padding-left: 1em;
  text-align: center;
}
.facebook {
  float: left;
  background-image: url(../facebook.png);
}
.instagram {
  float: left;
  background-image: url(../instagram.png);
}
.linkedin {
  float: left;
  background-image: url(../in.png);
}
.social {
  background-repeat: no-repeat;
  background-size: contain;
  vertical-align: middle;
  width: 2em;
  height: 2em;
}
.socialIcons {
  width: 20%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
}
.footerLeft {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.footerLeft a {
  text-decoration: none;
  color: $heibaWhite;
  font-weight: bold;
}
@media only screen and (min-width: 575px) {
  .footerLeft {
    flex-direction: row;
  }
  .footerLeft p{
    font-size: 1.3vh;

  }  
}
