.placementVideoCardLocation{
    border-style: solid;
    border-radius: 15px;
    margin: 4px;
    width: 16%;
    border: dashed;
    border-color: $heibaLightGray;
    display: flex;
    justify-content: center;
    align-items: center;
}
.placementVideoCard{
    border-style: solid;
    border-radius: 15px;
    margin: 4px;
    width: 95%;
    height: 95%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}
.placementVideoCard p{
    margin-left: 2px;
    font-size: 10px;
    word-wrap: break-word;
}

.videoPlacementCards{
    display: flex;
    flex-direction: row;
    height: 43vh;
}
.infoHolder{
    margin-bottom: 45px;
    width: 90%;
    height: 70%
}
.adminButton{
    display: block;
    padding: 6px 10px;
    border-radius: 40px;
    border: 4px solid #2d4662;
    margin-bottom: 8px;
    color: #fff;
    background-color: $btnBackOrange;
}
.adminButtonAbsolute{
    background-color: gray;
    position: absolute;
    color: white;
    width: 95%;
    bottom: 10px;
    
}
.placementVideoCardImage{
    width: 95%;
    position: absolute;
    bottom: 55px;
    height: 50%;
    border-style: solid;
    border-color: gray;
    border-width: 1px;
    object-fit: contain;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center;
}