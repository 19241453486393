.overlay{
    position: fixed;
    top: 21%;
    left: 0;
    width: 100%;
    height: 80vh;
    background: #f5fafec7;
    z-index: 999;
}
.box{
    margin: 10% auto 0 auto;
    max-width: Min(350px,100%);
    z-index: 1000;
    padding: 10px;
    background: #fff;
    text-align: center;
    font-size: 2em;
    border-style: solid;
    border-width: 4px;
    border-color: $btnBackOrange;
    width: 20em;
    height: 6em;
    border-radius: 0.5em;
}
.bigBox{
    height: 13em;
}
.box h3{
    font-size: .8em;
    margin-bottom: 0.1em;
    font-weight: bold;
    color: $heibaLightGray;
    font-size: 0.8em;

}
.box p{
    font-size: 0.5em;
    text-align: left;
    margin-left: .5em;

    font-weight: bold;
    color: $heibaLightGray;
}
.box button{
    width: 80%;
    background-color: $btnBackOrange;
    color: $heibaWhite;
    border-radius: .5em;
    height: 3em;
    font-weight: bold;
    font-size: 0.6em; 
}
.box button:hover{
    background-color: $heibaDarkGreen;
}

