.menuBar {
  z-index: 2 !important;
  position: fixed;
  top: 15;
  width: 100%;
  top: 5.2em;
  height: 3em;
  margin-top: 1em; 
  margin-left: 14em;
}

.menuTopBar {
  z-index: -1 !important;
  background-color: $heibaBlue;
  position: fixed;
  width: 100%;
  top: 1.0em;
  height: 4.2em;
  margin-top: 1em; 
  margin-left: 10vw;
  padding-right: 80px;
}

@media only screen and (min-width: 900px) {
  .menuBar {
    justify-content: flex-start;
    display: flex;
  }
}

.menuBar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-left: 3px;
}

.menuBar li {
  display: inline;
}

.menuBarRight {
  float: right;
  margin: 0.75em;
  color: $heibaWhite;
}

.menuBarTopRight {
  float: right;
  margin: 0.95em;
  margin-right: 0.2em;
  color: $heibaWhite;
}

.menuBarSubLeft {
  float: left;
  margin: 0.75em;
  color: $heibaWhite;
}

.menuBarSubLeft a {
  text-decoration: none;
  font-weight: bold;
  background-color: $heibaWhite;
  color: $heibaBlue;
  padding-left: 9px;
  padding-right: 9px;
  border-radius: 10px;
  padding-top: 4px;
  padding-bottom: 4px;
}

.menuBarSubLeft a:hover{
  color: $btnBackOrange
}

.menuBarTopRight, .menuBarRight a:hover{
  color: $btnBackOrange
}

.menuBarTopRight, .menuBarRight a {
  text-decoration: none;
  font-weight: bold; 
  background-color: $heibaWhite;
  color: $heibaBlue;
  padding-left: 9px;
  padding-right: 9px;
  border-radius: 14px;
  font-weight: bold;
  box-shadow: $heibaShadow;
  padding-top: 6px;
  padding-bottom: 6px;
}

.active a {
  color: $btnBackOrange;
}

.menuItems {
  max-width: 1400px;
  margin: auto;
}

@media only screen and (min-width: 900px){
  .menuItems{
    width: 85%;
  }
}
.dropdown {
  position: relative;
  display: inline-block;
  font-weight: bold;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: $heibaBlue;
  min-width: 160px;
}

.dropdown-content a {
  color: $heibaBlack;
  padding: 6px 16px;
  display: block;
  background-color: $heibaWhite;
  margin: 3px;
  border-radius: 6px;
}

.active  {
  color: $btnBackOrange;
}

.dropdown:hover .dropdown-content {display: block;}

#menuToggle
{
  display: none;
  position: absolute;
  top: 18px;
  right: 4em;
  z-index: 1;
  -webkit-user-select: none;
  user-select: none;
}

@media only screen and (max-width: 500px) {
  .menuBarSubLeft {
    display: none;
  }
  .menuItems{
    display: none ;
  }
  #menuToggle{
    display: block;
  }
  .logoBar p{
    display: none;
  }
}

#menuToggle a
{
  text-decoration: none;
  color: $heibaWhite;
  transition: color 0.3s ease;
}

#menuToggle a:hover
{
  color: tomato;
}

#menuToggle input
{
  display: block;
  width: 40px;
  height: 32px;
  position: absolute;
  top: -7px;
  left: -5px;
  cursor: pointer;
  opacity: 0; /* hide this */
  z-index: 2; /* and place it over the hamburger */
  -webkit-touch-callout: none;
}

#menuToggle span
{
  display: block;
  width: 33px;
  height: 4px;
  margin-bottom: 5px;
  position: relative;
  background: $heibaWhite;
  border-radius: 3px;
  z-index: 1;
  transform-origin: 4px 0px;
  transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
              opacity 0.55s ease;
}

#menu li
{
  padding: 10px 0;
  font-size: 14px;
  font-weight: bold;
}

body
{
  margin: 0;
  padding: 0;
}

#menu
{  
  position: absolute;
  width: 14em;
  right: -1.5em;
  top: 3.5em;
  -webkit-font-smoothing: antialiased;
  transform-origin: 0% 0%;
  transform: translate(100%, 0);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
}

#menuback{
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  width: 10em;
  left: -5em;
  top: 3.5em;
  padding-top: 1em;
  padding-bottom: 0.5em;
  background: white;
  -webkit-font-smoothing: antialiased;
  transform-origin: 0% 0%;
  transform: translate(100%, 0);
  transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
  border-radius: 20px 0 0 20px;
}

#menu li{
  padding-top: 1em;
  padding-bottom: 1em;
  padding-left: 2em;
}

#menu a{ 
  padding-bottom: 0em;
  width: 190%;
  background: $heibaBlue;
  border-radius: $heibaBorderRadiusMenu 0 0 $heibaBorderRadiusMenu;
  margin: 6px;
}

#menuToggle input:checked ~ div
{
  transform: none;
}

#menuToggle input:checked ~ ul
{
  transform: none;
}