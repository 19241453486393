.Oops h2 {
  margin: auto;
  font-weight: bold;
  color: $heibaLightGray; /* licht grijs */
  font-size: 6em;
  text-align: center;
}
.Oops h1 {
  margin: auto;
  font-weight: bold;
  color: $heibaGray;
  font-size: 0.8em;
  text-align: center;
}
.Oops p {
  margin: auto;
  font-weight: bold;
  color: $heibaLightGray; /* licht grijs */
  font-size: 0.8em;
  margin-top: 3em;
  width: 70%;
  text-align: center;
}
.Oops a {
  margin: auto;
  font-weight: bold;
  font-size: 0.8em;
  margin-top: 3em;
  text-align: center;
  width: 70%;
}
