.errorbox {
    ///overflow: hidden;   //why was this
    z-index: 1 !important;
    background-color: $heibaRed;
    position: fixed;
    width: 100%;
    
    bottom: 0px;
    height: 5em;
    margin-top: 1em; /* not sure about this*/
    padding: .7em;
    overflow: hidden;
    display: flex;
    align-items: center;

    animation-name: fadeAnimation;
    animation-duration: 4000ms;
    animation-fill-mode: forwards;
}
@media only screen and (min-width: 575px) {
    .errorbox {
        width: 30%;
        bottom: 3.2em;
        height: 5em;
        right: 20px;
        border-radius: 0.5em;
    }
}
@keyframes fadeAnimation{
    0%{
    //   opacity: 1;
      transform: rotateX(90deg);
    }
    50%{
    //   opacity: 0.5;
      transform: rotateX(0deg);
    }
    100%{
      display: none;
    //   opacity: 0;
      transform: rotateX(90deg);
    }
  }




.errorbox p{
      color: $heibaWhite;
      font-weight: bold;
      text-transform: uppercase;
      text-align: center;
}
.errorbox:hover{
    height: 7em;
}