.dropzone {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $heibaInputBackground ;
  // overflow: hidden !important;
  margin-bottom: 30px !important;
}
.dropzonePreview{
  background-color: $heibaDarkBackground;
  margin: 0.3em 0 0.3em 0;
  border-radius: 20px;
  width: 96% !important;
  overflow-x: hidden !important;
}
.dropzonePreview:first-of-type{ 
  background-color: $heibaDarkBackground;
  margin: 0.6em 0 0.3em 0;
}
.dropzonePreviewImage{
  width: auto;
  max-height: 120px;
  max-width: 140px;
  border-radius: 10px;
}
.dropzonePreviewTitle{
  padding-top: '0em';
  color: $heibaBlueOffical;
  font-weight: 600;
}
.dropzonePreviewSubTitle{
  padding-top: '0em';
  color: $heibaWhite;
  font-weight: 400;
  font-size: 14px;
}
.dropzoneTitle{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.imgQualityCheck{
  display: flex;
  align-items: center;
  flex-direction: column;
  max-width: 75px;
  margin: 10px;
}
.imgQualtyText{
  color: $heibaWhite;
  text-align: center;
}
.dzu-previewStatusContainer {
  background-color: gray;
  // max-width: 20%;
  // justify-content: right;
}
.dropzoneProgressbarHolder{
  display: flex;
  justify-content: center;
}
.dropzoneProgresAndQuality{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
//    /* For Chrome or Safari */
// progress::-webkit-progress-bar {
//     // background-color: #eeeeee;
// }

// progress::-webkit-progress-value {
//     background-color: #039603 !important;
// }


// /* For Firefox */
// progress {
//     background-color: #eee;
// }

// progress::-moz-progress-bar {
//     background-color: #039603 !important;
// }

// /* For IE10 */
// progress {
//     background-color: #eee;
// }

// progress {
//     background-color: #039603;
// }
// .dzu-previewStatusContainer progress {
//   max-width: 70%;
// }```````````````
// .dropzoneInputLabel{
//   background-color: yellowgreen;
// }
// .dropzoneInputLabelWithFiles{
//   background-color: blue;
// }
// .dropzoneInput{
//   background-color: wheat;
// }
@media screen and (min-width: 1200px) {
  .dropzone {
    font-size: 36px;
  }
}
.dropzoneAdminPreview {
  width: 95%;
  margin: auto;
}
.dropzoneAdminPreview i.icon {
  margin-left: 1em;
}
.droptext {
  margin-top: 0em;
  margin-bottom: 4em;
  color: $btnBackOrange;
  font-weight: bold;
  text-align: center;
  padding-top: 8vw;
}
.droptextCenter {
  margin-top: 0em;
  color: $btnBackOrange;
  font-weight: bold;
}

.uploadedFilesShow h4 {
  margin-top: 2em;
  margin-bottom: 1em;
  font-size: 2vh;
}
.uploadedFilesShow {
    width: 85%;
    margin: auto;
}
.dzu-inputLabelWithFiles{
  margin-bottom: 1em;
}

.dzu-dropzone{
z-index: 0;
}
.uploadSection{
  margin-top: 2em;
}

@media only screen and (max-width: 600px){
  .dzu-previewContainer{
    flex-wrap: wrap;
  }
  
  .dzu-previewContainer p {
    margin: 5px;
    margin-top: 13px;
  }
  .dzu-inputLabel{
    text-align: center;
    margin-left: 20px;
    margin-right: 20px;
  }
  
  .dzu-dropzone{
    flex-wrap: wrap;
    z-index: 0;
  }
  
  .dzu-previewStatusContainer{
    width: 100%;
    display: flex;
    justify-content: space-around;
    margin-top: 15px;
  }
}