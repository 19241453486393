.playerSearchHolder{
    display: flex;
    justify-content: center;
    align-items: center;
}

.playerSearch{
    min-width: 300px;
    max-width: 90vw;
    margin: auto;
}
