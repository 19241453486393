.selectWeek {
  margin: auto;
  min-height: 24em;
}
.selectWeek h2 {
  margin-bottom: 0.2em;
  color: $heibaWhite;
}
.selectWeek p {
  margin-top: 0.2em;
}
.selectWeekSubText{
  color: $heibaWhite;
}
.DayPicker {
  font-size: 1.3rem;
}
.selectWeek button {
  border-radius: 0.5em;
  height: 3em;
  margin-bottom: 4em;
}
.selectWeekHolder {
  display: flex;
  justify-content: center;
}
.festiveSuggestions {
  width: 18em;
}
.festiveSuggestions ul {
  list-style-type: none;
  padding-left: 10px;
}
.festiveSuggestions li {
  font-weight: bold;
  margin-bottom: .3em;
}
.festiveSuggestions .active li {
  font-weight: bold;
  color: $btnBackOrange
}
.inputCallenders {
  display: flex;
  flex-direction: column;
}
@media only screen and (min-width: 650px) {
  .inputCallenders {
    flex-direction: row;
  }
  .festiveSuggestions ul {
    margin-top: 4em;
  }
}
.inputSuggestions {
  width: 18em;
}
.inputSuggestions ul {
  list-style-type: none;
  padding-left: 10px;
}
.inputSuggestions li {
  font-weight: bold;
  color: $btnBackOrange;
  margin-bottom: .3em;
}
.inputSuggestions li:hover {
  color: $heibaDarkGreen
}
.inputSuggestions .active li {
  font-weight: bold;
  color: $btnBackOrange
}
@media only screen and (min-width: 650px) {
  .inputSuggestions ul {
    margin-top: 4em;
  }
}


