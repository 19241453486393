.hr{
  display: block;
  overflow: hidden;
  border-style: inset;
  border-width: 1px;
  margin-left: 1em;
  margin-right: 1em;
  border-color: $btnBackOrange;
  margin-bottom: 2em;
}
.hr.white{
  border-color: $heibaWhite;
}