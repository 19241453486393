.overlayImage{
    position: fixed;
    top: 0%;
    

    
    left: 50%;
    transform: translateX(-50%);

    background: #f5fafec7;
    z-index: 999;
}
.boxImage{
    margin: 10% auto 0 auto;
    // max-width: 350px;
    z-index: 1000;
    padding: 10px;
    background: #fff;
    text-align: center;
    font-size: 2em;
    border-style: solid;
    border-width: 4px;
    border-color: $btnBackOrange;
    // width: 100%;
    // height: 90%;
    border-radius: 0.5em;
}
.boxImage img{
    border-style: none;
    max-height: 80vh;
    max-width: 90vh;
}
