/* Normal desktop :1200px. */
@media (max-width: 1399px) {
    .header {
        overflow: hidden;
        padding: 0px 30px;
        padding-top: 20px;
        padding-bottom: 18px;
    }
    .header-bottom a {
        padding: 7px 11px;
        border: 3px solid #5dc2d7;
        font-size: 15px;
        margin: 0px 4px;
    }
    .hero-wrapper {
        height: 630px;
    }
    .hero-textarea-info {
        height: 160px;
        width: 160px;
    }
    .hero-textaarea textarea {
        height: 140px;
    }

    .border-dased {
        top: -11%;
    }


}


/* Normal desktop :992px. */
@media (max-width: 1299px) {
    /* home-2-start  */
    .banner-form {
        max-width: 930px;
        margin: 0 auto;
    }


}

 

/* Normal desktop :992px. */
@media (max-width: 1199px) {
    /* home-2 start */
    .banner-form {
        max-width: 800px;
        margin: 0 auto;
    }
    .form-text h3 {
        font-size: 50px;
        margin-bottom: 0px;
        line-height: 0.866;
        margin-top: -10px;
    }
    .form-text small {
        font-size: 20px;
    }
    .form-text {
        height: 150px;
        width: 150px;
        border: 5px solid #05161d;
    }

    /* update-css */
    .banner-img {
        height: 233px;
    }
    .border-dased span img {
        width: 63px;
        height: 63px;
    }






}

 
/* Tablet desktop :768px. */
@media (max-width: 991px) {

    /* home-2 start */
    .banner-img {
        margin-top: 0px;
        position: relative;
        margin-bottom: 40px;
        width: 100%;
        margin-left: 0px;
    }
    .form-text {
        height: 150px;
        width: 150px;
        border: 5px solid #05161d;
        bottom: 0%;
        right: 84%;
    }
    /* home-2 start-end */
    .banner-img {
        height: 168px;
    }
    .border-dased span img {
        width: 48px;
        height: 48px;
    }


}

 
/* small mobile :320px. */
@media (max-width: 767px) {
    

    .heder-logo a img {
        max-width: 161px;
    }
    .header-wrapper {
        height: 42px;
    }
    .header {
        overflow: hidden;
        padding: 0px 15px;
        padding-top: 16px;
        padding-bottom: 10px;
    }
    .header-bottom {
        margin-top: 15px;
        flex-wrap: wrap;
    }
    .header-bottom p {
        margin-right: 0px;
        width: 100%;
        margin-bottom: 7px;
    }
    .header-bottom a {
        padding: 4px 12px;
        border: 2px solid #5dc2d7;
        font-size: 13px;
        margin: 0px 4px;
    }
    .hero-wrapper {
        height: 450px;
    }
    .hero-textarea-info {
        height: 100px;
        width: 100px;
        border: 4px solid;
    }
    .hero-textarea-info h5 {
        font-size: 12px;
        margin-bottom: 0px;
    }
    .hero-textaarea textarea {
        height: 90px;
        padding: 10px;
        padding-left: 110px;
    }
    .hero-text {
        max-width: 90%;
        padding: 12px 18px 15px;
    }

    .textarea-box-img img {
        height: 258px;
    }
    .extra-img img {
        height: 320px;
    }

    .border-title {
        margin-bottom: 20px;
    }

    .border-title::after {
        top: 44%;
    }
    .footer{
        text-align: center;
    }
    .extra-texarea-btns button {
        height: 47px;
        width: 125px;
        font-size: 15px;
    }
    .footer-social a {
        margin-left: 0px;
        margin: 0px 10px;
    }

    /* home-2-start */
    .banner-form textarea {
        height: 115px;
        border-radius: 26px;
        padding: 10px 11px 10px 69px;
        font-size: 16px;
    }
    .banner-form textarea::placeholder{
        font-size: 18px;
        line-height: 80px;
    }
    .form-text small {
        font-size: 10px;
    }
    .form-text h3 {
        font-size: 24px;
        margin-bottom: 0px;
        line-height: 0.866;
        margin-top: -10px;
    }
    .form-text {
        height: 80px;
        width: 80px;
        border: 5px solid #05161d;
        bottom: 56%;
        right: 80%;
    }
    .banner-area {
        padding-bottom: 53px;
    }
    .card-box-m-white h4.black-text{
        color: #fff !important;
    }
    .card-box-m-white p.black-text{
        color: #fff !important;
    }
    .extra-texarea-btns .extra-btn {
        background-color: #53cdde;
        height: 110px;
        width: 110px;
    }
    /* home-2-start-end */
    .banner-icons img.shoping-trolli {
        margin-right: 0%;
    }

    .border-dased span img {
        width: 30px;
        height: 30px;
    }
    
    .border-dased {
        top: -90px;
    }

    .banner-img {
        height: auto;
        margin-bottom: 17px;
        width: 100%;
        margin-left: 0px;
    }

    .banner-img {
        margin-top: 14px;
    }

 
}
 
/* Large Mobile :480px. */
@media only screen and (min-width: 480px) and (max-width: 767px) {
.container {width:450px}
    
.banner-img {
	height: 99px;
}
.border-dased span img {
	width: 30px;
	height: 30px;
}
.border-dased {
    top: -11%;
}







}
