.GeneralMessageBar {
    z-index: 1 !important;
    position: fixed;
    width: 100%;
    top: 9em;
    height: 3em;
    margin-top: 1em; 
    display: flex;
    justify-content: center;
}

@media only screen and (max-width: 900px) {
    .GeneralMessageBar {
        justify-content: center;
        display: flex;
        height: 4em;
    }
}
@media only screen and (max-width: 600px) {
    .GeneralMessageBar {
        height: 7em;
    }
    // .generalMessage {
    //     padding-top: 17.5em !important;
    // }
}

.GeneralMessageFrame{
    width: 95%;
    border-radius: 20px;
    background-color: $btnBackOrange;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 15px;
}
.GeneralMessageText{
    padding-bottom: 0px;
    margin: auto;
    font-family: $heibaFontCapsOffical;
    font-weight: 600;
}
