$heibaGray:  #4d4d4d; /* donker grijs */
$btnBackOrange: #fa4609;
$heibaLightGray:  #878a8f; /* licht grijs */
$heibaLightBlue: #f5fafe; /* heel licht blauw invul veld  */
$heibaGreen: #47d147; /* light green*/
$heibaDarkGreen: #70b960; /* darker green*/
$heibaLightGreen: #d5e7c9; /* light green*/
$heibaRed: #ff4d4d;
$heibaLightRed: #fad0be;
$heibaWhite: white;
$heibaBlack: black;
$heibaTest: red;
$heibaBlue: #5dc2d7;

$heibaBlueOffical: #53cdde;
$heibaLightBlueOffical: #cae7ef;
$heibaBlackOfficial: #333333;
$heibaInputBackground: #F5E1CF;
$heibaDarkBackground: #05161d;

$heibaFontCapsOffical: "Futura MD BT BOLD";
$heibaFontOffical: "Open Sans bold";

$heibaBorderRadiusMenu: 10px;;
$heibaBorderRadiusInput: 20px;;
$heibaBorderRadius: 2pc;
$heibaBorderWidth: 2px;

$heibaShadow: 2px 2px 4px #888888;