.InputNavigation button{
    // background-color: #121312;
    height: 3em;
    float: right;
    margin-right: 0em;
    border-radius: 0.5em;
    width: 10em;
    font-weight: bold;
    color: $heibaWhite;
    margin-top: 1em;    
}
.InputNavigation button:nth-child(2){
    margin-right: 2.5em;
}

.InputNavigation{
    padding-bottom: 9em;
}


.backButton{

    background-color: #70b960;
    color: "white"; 
    opacity: 0.5;
 
}
.backButton:hover{
    background-color: #ec7026;
    opacity: 1.0;
}
// .nextButton:hover{
//     background-color: #70b960;
//     height: 20em;
// }