// .guidanceText{
//     color: $heibaWhite;
// }


@font-face {
    font-family: 'myriad_probold';
    src: url('../../public/fonts/myriad_pro_bold-webfont.woff2') format('woff2'),
         url('../../public/fonts/myriad_pro_bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'myriad_proregular';
    src: url('../../public/fonts/myriad_pro_regular-webfont.woff2') format('woff2'),
         url('../../public/fonts/myriad_pro_regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
@font-face {
    font-family: 'myriad_prosemibold';
    src: url('../../public/fonts/myriad_pro_semibold-webfont.woff2') format('woff2'),
         url('../../public/fonts/myriad_pro_semibold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'myriad_procondensed';
    src: url('../../public/fonts/myriad_pro_condensed-webfont.woff2') format('woff2'),
         url('../../public/fonts/myriad_pro_condensed-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}


*{
    margin: 0;
    padding: 0;
    list-style: none;
    box-sizing: border-box;
}
// body{
//     font-size: 16px;
//     line-height: 18px;
//     font-weight: normal;
//     background-color: #23363d;
//     font-family: "myriad_proregular";
// }
img{
    max-width: 100%;
    transition: all 0.3s ease-out 0s;
}
a{
    text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
h6{
    font-weight: 600;
    color: #fff;
    margin: 0 0 10px;
    line-height: 1.3;
    font-family: "myriad_proregular";
}
h1{
    font-size: 40px;
}
h2{
    font-size: 32px;
}
h3{
    font-size: 28px;
}
h4{
    font-size: 24px;
}
h5{
    font-size: 20px;
}
h6{
    font-size: 18px;
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
	color: inherit;
}
p {
	font-size: 16px;
	line-height: 18px;
	color: #fff;
	margin: 0 0 10px;
}
label {
	color: #7e7e7e;
	cursor: pointer;
	font-size: 14px;
	font-weight: 400;
}
*::-moz-selection {
	background: #d6b161;
	color: #fff;
	text-shadow: none;
}
::-moz-selection {
	background: #444;
	color: #fff;
	text-shadow: none;
}
::selection {
	background: #444;
	color: #fff;
	text-shadow: none;
}
*::-moz-placeholder {
	color: #555555;
	font-size: 14px;
	opacity: 1;
}
*::placeholder {
	color: #555555;
	font-size: 14px;
	opacity: 1;
}
textarea:focus,
input:focus{
    outline: none;
}





/* heder-css */
.header {
	overflow: hidden;
	padding: 0px 45px;
    padding-top: 36px;
	padding-bottom: 38px;
}

.header-wrapper {
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: #53cdde;
	position: relative;
	height: 96px;
	border-top-left-radius: 15px;
	border-bottom-left-radius: 15px;
}
.header-wrapper::after{
    position: absolute;
    left: 100%;
    top: 0;
    width: 9999px;
    height: 100%;
    content: "";
    background-color: #53cdde;
}
.heder-logo {
	position: absolute;
	left: 0px;
	top: 68%;
	transform: translateY(-50%);
}
.heder-logo a img {
	max-width: 312px;
}

.header-nav ul {
	display: flex;
	align-items: center;
	margin-bottom: 0px;
}
.header-nav ul li {
    padding: 0px 20px;
}

.header-nav ul li a {
	display: block;
	font-size: 16px;
	color: #5dc2d7;
	padding: 13px 18px;
	box-shadow: 8px 10px 9.8px 0.2px rgba(0, 0, 0, 0.2);
	background-color: #fff;
	border-radius: 20px;
    transition: .3s;    
}
.header-nav ul li a:hover{
    box-shadow: 8px 15px 10.8px 0.7px rgba(0, 0, 0, 0.2);
}
.header-nav {
	margin: 0 0 0 auto;
}
// .header-nav{}

.header-bottom{
    display: flex;
    align-items: flex-start;
    margin-top: 20px;
}
.header-bottom a {
	display: inline-block;
	padding: 9px 18px;
	border-radius: 10px;
	background-color: #fff;
	color: #5dc2d7;
	border: 4px solid #5dc2d7;
	font-size: 16px;
	margin: 0px 4px;
}
.header-bottom p{
    margin-right: 40px;
}

.m-toggle span{
    height: 1.5px;
    width: 100%;
    display: block;
    background-color: #fff;
}
.m-toggle {
	height: 21px;
	width: 30px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;
    cursor: pointer;
}
.offcanvas-menu ul{
    padding: 0px;
}
.offcanvas-menu ul li a{
	display: block;
	padding: 9px 18px;
	border-radius: 10px;
	background-color: #fff;
	color: #5dc2d7;
	border: 4px solid #5dc2d7;
	font-size: 16px;
	margin: 0px 4px;
    text-align: center;
    margin-bottom: 10px;
}

/* heder-css-end */





/* season-area */
.season-area{
    padding-top: 40px;
}
.season-title h4{
    color: #fa4608;
}

.card-box.black-text h4{
    color: #000;
}
.card-box.black-text p{
    color: #000;
}
// .card-box.black-text{}
// .card-box{}
// .card-box{}
// .card-box{}


.textarea-box-img{
    margin-bottom: 20px;
    margin-top: 20px;
}
.textarea-box-img img {
	height: 392px;
	width: 100%;
	object-fit: cover;
	object-position: center;
}
// .textarea-box.box-color-1{}
.textarea-box .text-area textarea{
    width: 100%;
    background-color: #fff;
    padding: 14px 20px;
    font-size: 16px;
    color: #fa4608;
    height: 120px;
    border: 2px solid #fa4608;
    border-radius: 20px;
    resize: none;
}
.textarea-box .text-area textarea::placeholder{
    font-size: 16px;
    color: #fa4608;
}
// .textarea-box .text-area{}
// .textarea-box{}



.video-box-img{
    height: 300px;
    width: 100%;
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 20px;
}
.video-list-item{
    display: block;
    padding: 6px 10px;
    background-color: #fa4608;
    border-radius: 40px;
    border: 4px solid #2d4662;
    margin-bottom: 8px;
    color: #fff;
}
.video-list-item-blue{
    background-color: #53cdde;
}
.video-box-img img {
	max-width: 183px;
}
.video-list p{
    text-align: center;
	text-transform: uppercase;
}
.video-list-selected {
    text-align: center;
    color: #fa4608;
}

.border-title{
    position: relative;
    z-index: 1;
    text-align: center;
    margin-bottom: 30px;
    margin-top: 20px;
}
.border-title::after{
    position: absolute;
    left: 0px;
    top: 50%;
    width: 100%;
    height: 2px;
    background-color: #fff;
    content: "";
    margin-top: -1px;
    z-index: -1;
}
.border-title h4{
    display: inline-block;
    padding: 0px 20px;
    background-color: #23363d;
}
.border-title.darkyello-text h4{
    color: #a28e2b;
}
.card-box.card-color-2 .textarea-box .text-area textarea{
    border-color: #a28d2b;
    color: #a28d2b;
}
.card-box.card-color-2 .textarea-box .text-area textarea::placeholder{
    color: #a28d2b;
}
.card-box.card-box-2 .video-list a{
    background-color: #a28d2b;
}
.card-box.card-box-2 .video-list p{
    color: #a28d2b;
}


/* season-area-end */

/* gallery-start */
.gallery-area{
    padding-top: 40px;
}
.gallery-wrapper{
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 20px;
    max-width: 90%;
    margin: 0 auto;
}
/* gallery-end */



/* extra-area */
.extra-wrapper{
    max-width: 800px;
}
.extra-wrapper p a em{
    color: #53cdde;
}
.extra-wrapper-em{
	color: #53cdde;
	font-weight: bolder;
}


// .extra-textbox{}
.extra-textbox textarea {
	width: 100%;
	background-color: #fff;
	padding: 14px 20px;
	font-size: 16px;
	color: #53cdde;
	height: 120px;
	border: 2px solid #53cdde;
	border-radius: 20px;
	resize: none;
	box-shadow: 8px 8px 4.8px -2.8px rgba(0, 0, 0, 0.2);
	overflow: hidden;
}
.extra-textbox textarea::placeholder{
    font-size: 16px;
    color: #53cdde;
}

.extra-texarea-btns{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0px 0px;
}
.extra-texarea-btns button{
    display: inline-block;
    text-align: center;
    color: #fff;
    background-color: #53cdde;
    border: none;
    height: 50px;
    width: 150px;
    border-radius: 10px;
    font-size: 17px;
    cursor: pointer;
    margin-left: 10px;
    box-shadow: 8px 8px 4.8px -2.8px rgba(0, 0, 0, 0.2);
}
.extra-img {
	margin-top: -140px;
	position: relative;
	z-index: -1;
}
.extra-img img{
	height: 550px;
    width: 100%;
    object-fit: cover;
}
.border-title.extra-info-title h4{
    color: #53cdde;
}
.ps-0{
	margin-bottom: 1em;
	color: #53cdde;
}
/* extra-area-emd */



/* footer-area */
.footer-wrapper{
    padding: 15px 0px 5px;
}
.footer-wrapper p{
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    margin-top: 0px;
}
.footer-social a {
    display: inline-block;
    font-size: 17px;
    margin-left: 20px;
}
.footer-social a i{
    color: #fff;
}

/* footer-area-end */


/* ============================ */
/* ====== Home-page-2-css-start
/* ============================ */

.bg-darkblue{
    background-color: #05161d !important;
}
.bg-lightgold{
    background-color: #f5e1cf !important;
}
.grey-bg{
    background-color: #4d4d4d !important;
}

.black-text{
    color: #231f20 !important;
}
.sea-text{
    color: #53cdde !important;
}


// .banner-area{

// }
.banner-wrapper{
	margin-top: 10%;
}
.banner-content-text {
	max-width: 683px;
	margin: 0 auto;
} 
.banner-content-text p {
	font-size: 18px;
	line-height: 1.3;
}  
.banner-img {
	margin-top: -117px;
	position: relative;
	margin-bottom: 40px;

	margin-left: 10%;
	width: 80%;

}
.banner-content-text {
	max-width: 683px;
	margin: 0 auto;
	margin-bottom: 34px;
}
.banner-content-text p {
	font-size: 18px;
	line-height: 1.3;
}
.banner-form {
	max-width: 1100px;
	margin: 0 auto;
}
.form-text {
	height: 200px;
	width: 200px;
	background: #fd6200;
	text-align: center;
	border-radius: 50%;
	border: 10px solid #05161d;
	position: absolute;
	bottom: 46%;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	right: 91%;
}
.banner-form textarea::placeholder {
	font-size: 20px;
	color: #23363d;
	text-align: center;
	line-height: 104px;
}
.banner-form textarea {
	background: #f5e1cf;
	width: 100%;
	resize: none;
	height: 144px;
	border-radius: 26px;
	border: none;
	padding: 20px 20px 20px 110px;
    font-size: 20px;
	color: #23363d;
}
.banner-form {
	position: relative;
}
.season-after{
    z-index: 1;
}
.season-after::after{
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 400px;
    background-color: #f5e1cf;
    content: "";
    z-index: -1;
}




.season-after::after {
	position: absolute;
	left: -58px;
	top: -38px;
	width: 100%;
	height: 393px;
	background-color: #f5e1cf;
	content: "";
	z-index: -1;
	border-top-left-radius: 40px;
	border-bottom-left-radius: 40px;
}
.season-after::before {
	position: absolute;
	left: calc(100% - 58px);
	top: -38px;
	width: 9999px;
	height: 393px;
	background-color: #f5e1cf;
	content: "";
	z-index: -1;
}
.banner-area {
	padding-bottom: 80px;
}

.textarea-box .text-area.textarea-center textarea::placeholder{
    text-align: center;
    font-size: 20px;
    line-height: 84px;
}

.border-title.sea-text h4{
    color: #53cdde;
    background-color: #05161d;
}
.card-box.card-color-3 p a {
    color: #53cdde;
}
.card-box.card-box-3 .video-list a{
    background-color: #53cdde;
    color: #05161d;
}


.card-box.card-color-3 .textarea-box .text-area textarea::placeholder {
	font-size: 20px;
	color: #53cdde;
    text-align: center;
    line-height: 84px;
}
.card-box.card-color-3 .textarea-box .text-area textarea{
    border-color: #53cdde;
}

.extra-textbox.textbox-two textarea{
    height: 180px;
    color: #05161d;
    font-size: 18px;
    padding-bottom: 47px;
}

.extra-textbox.textbox-two textarea::placeholder {
	font-size: 20px;
	color: #05161d;
	text-align: center;
	line-height: 148px;
}

.extra-texarea-btns .extra-btn{
    background-color: #53cdde;
    height: 130px;
    width: 130px;
    border-radius: 50%;
    border: 5px solid #05161d;
    color: #05161d;
	z-index: 1;
	border-color: $heibaDarkBackground;
    border-width: 8px;
    border-style: solid !important;
	font-size: 0.9rem;
    font-weight: 600;
}
.extra-texarea-btns .extra-btn.extra-btn-theme{
    background-color: #fa4608;
    color: #fff;
    font-weight: 700;

}
.extra-texarea-btns.extra-textarea-btns-top {
	margin-top: -61px;
	margin-right: 24px;
}


.form-text h3 {
	font-size: 55px;
	line-height: 1;
	font-weight: normal;
    font-family: 'myriad_procondensed';
}

.form-text small {
	font-size: 24px;
}





/* banner-img-css */
.banner-img {
	height: 280px;
	// width: 100%;
	display: flex;
	align-items: flex-end;
	margin-top: -41px;
}

.banner-left {
	max-width: 866px;
	width: 66.8%;
	flex: 0 0 auto;
}
.banner-icons img.shoping-trolli {
	max-width: 15%;
	margin-right: 4%;
}
.banner-icons img.box-icon {
	max-width: 9.8%;
	margin: 0px 1.1%;
}
.banner-icons {
	padding: 0px 3%;
	position: relative;
	z-index: 0; //changed was 2
	top: 9px;
}
.banner-border {
	position: relative;
	top: 0px;
}


.banner-right {
	flex-grow: 1;
	height: 100%;
}
.screen-img img {
	width: 124%;
	position: absolute;
	bottom: -14px;
	max-width: inherit;
	right: 0px;
	z-index: 0; //changed
}
.border-dased span img {
	width: 88px;
	height: 88px;
}
.border-dased {
	width: 73%;
	position: absolute;
	left: 0px;
	top: -28%;
	z-index: 0; //changed
}
.border-dased::after {
	position: absolute;
	left: 10%;
	top: -14%;
	width: 90%;
	height: 100%;
	background-image: url('../../public/img/banner/border-dot.png');
	background-size: 100% auto;
	background-repeat: no-repeat;
	content: '';
	z-index: -1;
}
.banner-tag {
	position: absolute;
	right: 99%;
	top: 90%;
	width: 6%;
}







/* banner-img-css-end */








/* ============================ */
/* ====== Home-page-2-css-end
/* ============================ */



