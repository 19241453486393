.seasonFocus h3 {
  margin: 0.5em;
  margin-bottom: 0em;
}
.step {
  float: left;
  color: $heibaGray; /* donker grijs */
}
.stepNumber {
  float: right;
  color: $btnBackOrange;
}
.seasonFocus p {
  padding-left: 1em;
  padding-right: 1em;
}
.seasonFocus h2 {
  text-transform: uppercase;
  color: $heibaLightGray; /* licht grijs */
  font-size: 1.3em;
  font-weight: bold;
  margin: auto;
  text-align: center;
  margin: 0.5em;
}
.seasonProductHolder {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  align-content: baseline;
}
.seasonProductImage {
  width: 6em;
  height: 10vh;
  float: left;
  //TODO don't like the dependance on this external url
  background-image: url(https://images.hermie.eu/images/articles/large/plantenfiche-fragaria-x-ananassa-sonata-aardbei-sonata-15322.jpg);
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: 1em;
  vertical-align: middle;
  margin-top: 1em;
}
.seasonText{
  display: none;

}
.firstP{
    padding-top: 4em;
}
@media only screen and (min-width: 900px) {
  .seasonProductImage {
    height: 10vh;
    /* this is double i guess */
  }
  .seasonText {
    display: block;
  }
  .firstP{
      padding-top: 0em;
  }
}
.seasonFocus textarea {
  border-style: solid;
  border-width: 3px;
  border-color: $btnBackOrange;
  margin: auto;
  width: 100%;
  height: 7em;
  // margin-right: 2em;
  // margin-left: 2em;
}
.task {
  margin-top: 2.5em;
  margin-left: 1.2em;
  color: $btnBackOrange;
  font-weight: bold;
}
.videoPlayer {
  margin-top: 1.5em;
  margin-bottom: 1.2em;
  border-style: solid;
  border-color: #fa4608;;
  border-width: 3px;
  
}
.videoPlayer-blue {
  border-color: #53cdde;;
}



@media only screen and (min-width: 900px) {
  .videoPlayer {
    margin-top: 1.5em;
    margin-bottom: 1em;
    //height: 13em;
    //width: 85%;  //needed for preview
  }
}



.videoPlayerPlaylist p {
  border-style: solid;
  border-radius: 0.5em;
  color: $heibaWhite;
  height: 2.2em;
  background-color: $btnBackOrange;
  text-align: left;
  padding-top: 0.2em;
  padding-bottom: 0.2em;
  margin-top: 0em;
  margin-bottom: 0em;
  white-space: nowrap;
  display: flex;
  justify-content: left;
  align-items: center;
  cursor: default;
  font-weight: bold;
}
.PlayerHolder {
  padding-bottom: 1em;
}
.back {
  float: left;
  background-color: #d2ff2c;
  color: black;
}

@media only screen and (min-width: 900px) {
  .seasonHolder {
    display: flex;
    justify-content: space-between;
    padding-top: 3em;
    width: 100%;
  }
  .seasonInfo {
      width: 45%;
    }
    .seasonVideo {
        width: 50%;
        
    }
  .videoPlayerPlaylist{
    width: 100%;
  }

}


