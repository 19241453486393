@import './basics';

.download {
  width: 85%;
  max-width: 12em;
  margin-left: 1em;
  margin-right: 1em;
  margin-top: .5em;
}


.download h2 {
  margin: auto;
  color: $heibaGray; 
  font-weight: bold;

  margin-top: 0.3em;
  margin-bottom: 0.2em;
}

.download p{
  font-weight: bold;
  color: $heibaWhite;
  font-size: 0.8em;
  margin: auto;
  width: 100%;
  text-align: center;
  margin-top: 20px;
}
.fileDownload{
  color: $heibaLightGray;
  margin-top: 1em;
}
.downloadButton button{
  background-color: $btnBackOrange;
  border-radius: 0.5em;
  height: 9vw;
  color: $heibaWhite;
  font-weight: bold;
  border-style: none;
  max-height: 35px;
  
}
.filename{
    width: 5em;
    float: left;
    margin-top: 0em;
    font-weight: bold;
}
.downloadButton button:hover{
  background-color: $heibaDarkGreen;
 }

.downloadButtonGradient{
  border-radius: 50%;
  background-image: -webkit-linear-gradient( 120deg, rgb(156,130,37) 13%, rgb(120,168,130) 45%, rgb(83,205,222) 100%);
  background-image: -ms-linear-gradient( 120deg, rgb(156,130,37) 13%, rgb(120,168,130) 45%, rgb(83,205,222) 100%);
  background-image: linear-gradient( 180deg,$heibaBlueOffical 13%, rgb(128, 101, 101) 45%, $btnBackOrange 100%);
  height: 12em;
  width: 12em;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.downloadButtonGradientText{
  font-size: 20px !important;
  margin: auto !important;
  font-size: 20px !important;
  text-transform: uppercase;
  color: $heibaWhite;
}

@media only screen and (max-width: 900px) {
  .fileDownload {
    display: flex;
    justify-content: center;
  }
}
