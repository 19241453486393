.logoBar {
    overflow: hidden;
    background-color: $heibaDarkBackground;
    position: fixed; 
    top: 0; 
    width: 100%; 
    z-index: 2 !important;
    height: 9em;
  }
  .logoBar img{
    float: left;
    display: block;
    text-align: center;
    padding: 14px 16px;
    width: 38em;
    max-width: 17em;
    min-width: 179px;
    min-height: 99px;
  }
  .logoBar h1{
    margin-top: 0.2em;
    margin-bottom: 0;
    color:gray;
  }
  .logoBar p {
    margin: 0.2em;
    color:gray;
    max-width: 82%;
    left: -23em;
    bottom: -11.3em;
    position: relative;
    font-size: 9px;
    color: $heibaWhite; 
  }
 
