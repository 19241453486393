.inputEnough{
    display: flex;
    align-items: center;
    flex-direction: column;
}
.inputEnough h1{
    color: $btnBackOrange;
    margin-top: 1em;
    margin-bottom: 5vw;
}
.inputEnoughText{
    display: flex;
    align-items: flex-start;
    flex-direction: column;
}
.inputEnough p{
    color: $heibaBlackOfficial;
    max-width: 90%;
    font-weight: bold;

}