.buttonSend {
    background-color: $btnBackOrange; 
    font-weight: bold;
    color: $heibaWhite;
    text-transform: uppercase;
    height: 9vw;
    border-radius: 0.5em;
    border-style: none;
    max-height: 43px;
  }
.buttonSend:hover {
    background-color: $heibaDarkGreen; 
  }
  .buttonSendBlocked {
    background-color: $heibaLightGreen;
    color: $heibaWhite;
    font-weight: bold;
    text-transform: uppercase;
    height: 9vw;
    border-radius: 0.5em;
    border-style: none;
    max-height: 43px;
  }