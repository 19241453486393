


.comments {
  width: 98%;
  margin: auto;
  margin-top: .5em;
}

@media only screen and (min-width: 1120px) {
  .comments {
    max-width: 600px;
  }
}
.comments h2 {
  margin: auto;
  color: $heibaWhite;
  font-weight: bold;
  margin-top: 0.3em;
  margin-bottom: 0.2em;
}
.comments p {
  margin: auto;
  font-weight: bold;
  color: $heibaWhite; 
  font-size: 0.8em;
}
.comments textarea {
  margin: auto;
  width: 100%;
  height: 5em;
  background-color: $heibaInputBackground;
  margin-bottom: 1.5em;
  margin-top: 1em;
  resize: none;
  border-color: $btnBackOrange;
  border-style: solid;
  border-width: 3px;
  
  
}
.previewButtons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 1em;
}

.comments button {
  height: 9vw;
  border-radius: 0.5em;
  font-weight: bold;
  border-style: none;
  max-height: 35px;
  padding: 5px;
  margin-left: 10px;

}
.buttonAccept {
  background-color: $heibaBlueOffical;
  font-weight: bold;
  color: $heibaWhite;
  text-transform: uppercase;
}
.buttonAcceptBlocked {
  background-color: $heibaLightBlueOffical;
  color: $heibaWhite;
  font-weight: bold;
  text-transform: uppercase;
}

.buttonDecline {
  background-color: $heibaRed;
  color: $heibaWhite;
  font-weight: bold;
  text-transform: uppercase;
}

.buttonDeclineBlocked {
  background-color: $heibaLightRed;
  color: $heibaWhite;
  font-weight: bold;
  text-transform: uppercase;
}
.previewVideoPlayer {
  margin-top: 1.5em;
  margin-bottom: 1.2em;
  border-color: $heibaBlueOffical;
  border-style: solid;
  border-width: 3px;
}

@media only screen and (min-width: 900px) {
  .previewVideoPlayer {
    margin-top: 1.5em;
    margin-bottom: 4.5em;
    width: 85%;
    max-width: 1150px;
  }
  .comments{
    margin-left: 1em; 
    margin-right: 1em;
  }
}