
.ui.table td {
    padding: .28571429em .68571429em;
}

.ui.table thead th{
    padding: .62857143em .38571429em

}
.adminContainer{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: stretch;
    
}
.adminContainer div{
    padding-right: 0.8em;
    padding-bottom: 0.8em;
    padding-left: 0.8em;
}

.adminItem {
    // border-style: dashed;
    margin: 1em;
    min-width: 350px;
    flex-grow: 1;
    // flex-shrink: 1;
    border-style: solid;
    border-width: $heibaBorderWidth;
    border-radius: $heibaBorderRadius;
    border-color: $heibaBlue;
}
.adminItem h1{
    margin-top: 0.8em;
}
.largeAdminItem h1{
    margin-top: 0.8em;
}

.adminItem div{
    margin-left: 1em;
    margin-right: 1em;
}
.largeAdminItem{
    // border-style: solid;
    margin: 1em;
    min-width: 75%;

    flex-grow: 1;
    // flex-shrink: 1;
    
    // background-color: $heibaBlue;
}
// .adminItem:first-of-type div{
//     display: flex;
//     justify-content: center;
// }

@media only screen and (max-width: 900px) {
    .adminItem{
        min-width: 90%;
    }
    .largeAdminItem{
        min-width: 90%;
        max-width: 100%;
        overflow-x: scroll;
    }
  }


.adminItem h1:first-of-type{
    margin-bottom: 1em;
    margin-left: 1em;
 }

 .officeHome{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
 }

 .typeOfVideo form{ 
    display: flex;
    justify-content: center;
    align-items: center;
 }
 .typeOfVideo input{
     margin: 1em;
     margin-right: 0.3em;
     margin-left: 1.5em
 }

 .inputFlow{
     display: flex;
 }

table{
    background-color: $heibaInputBackground !important;
}
th{
    background-color: #C4B4A5 !important;
}
li{
    color: white;
}
.accountmanagersExplanation{
    color: white;
}
