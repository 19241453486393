.inputTypeSelector{
    height: 20em;
}
.inputTypeSelectorInstruction{
    margin-bottom: 1.5em;
}
.inputTypeSelectorTopRow{
    display: flex;
}
.inputTypeSelectorTopLeft{
    background-color: $heibaBlueOffical;
    width: 50%;
    max-height: 7em;
    border-radius: 35px 0px 0px 35px;
    min-height: 7em;
}
.inputTypeSelectorTopRight{
    background-color: $btnBackOrange;
    width: 50%;
    right: 0;
    border-radius: 0px 40px 40px 0px;
}
.inputTypeSelectorCenterRow{
    display: flex;
    justify-content: center;
}
.inputTypeSelectorCenter{
    background-color: gray;
    height: 10em;
    width: 10em;
    border-radius: 100%;
    border-color: $heibaDarkBackground;
    border-width: 6px;
    border-style: solid;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    top: -5.5em;
background: linear-gradient(180deg, $heibaBlueOffical 23%, $btnBackOrange 85%);
}
.inputTypeSelectorCenter p{
    text-align: center;
    color: $heibaWhite;
    font-family: $heibaFontOffical;
    font-weight: bolder;
    font-size: 16px;    
}
.inputTypeSelectorText{
    color: $heibaWhite;
    font-size: 4.5vw;
}
.inputTypeSelectorTopRight, .inputTypeSelectorTopLeft{
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}
.inputCallendersHolder{
    width: 100%;
    border-style: solid;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-wrap: wrap;
    border-color: $heibaBlueOffical;
    border-radius: 20px;
    border-width: 4px;  
    margin-bottom: 1em;
    background-color: $heibaBlackOfficial;
}
@media only screen and (max-width: 900px) {
    .inputTypeSelectorCenter {
        height: 5em;
        width: 5em;
    }
    .inputTypeSelectorTopLeft, .inputTypeSelectorTopRight{
        max-height: 4em;
        min-height: 3em;
    }
    .inputTypeSelectorTopRow{
        margin-top: 3em;
    }
    .inputTypeSelectorCenter p{
        font-size: 10px;
            
    }
    .inputTypeSelectorCenter{
        top: -2.5em;
    }
    .inputTypeSelectorText{
        font-weight: bold; 
    }
  }