.productFocus{
    margin-top: 2em;
    max-width: 796px;
    margin: auto;
}
.productFocus h3{
    margin: .5em;
    margin-bottom: 0em;
    font-size: 16px;
    color: $heibaWhite;
    
}
.step{
    float: left;
    color: $heibaGray; /* donker grijs */
}
.stepNumber{
    float: right;
    color: $btnBackOrange;
    
}
.stepNumber h4{
    color: $heibaWhite;
    text-align: center;
    margin-bottom: 1em;
    font-size: 14px;
}
.stepNumber h3{
    font-size: 18px;
    color: $btnBackOrange;
}
.stepExplanation{
    width: 75%;
    position: relative;
    bottom: -2.5em;
}
.stepName{
    width: 50%;
    position: relative;
    bottom: -2em;

}
.productFocus p{
    padding-left: 1em;
    padding-right: 1em;
    
}
.productFocus h2{
    text-transform: capitalize;
    color: $heibaLightGray; /* licht grijs */
    font-size: 1.3em;
    font-weight: bold;
    margin: auto;
    text-align: center;
}
.focusproductHolder{
    display: flex;
    justify-content: center;
    width: 95%;
    margin: auto;

}

.focusProductImage{
        width: 100%;  //whaat?
        height: 23vw;
        background-repeat: no-repeat;
        background-position: center;   
        background-size: contain;
        vertical-align: middle;
       
        // margin-right: 1em;
        margin-top: 1em;
        // max-width: 250px;
        

        //old(before onepage input
        // width: 18em;  //whaat?
        // height: 20vw;
        // float: left;
        // background-repeat: no-repeat;
        // background-size: contain;
        // vertical-align: middle;
        // margin-right: 1em;
        // margin-top: 1em;
        // max-width: 250px;
        // margin-top: 1em;
    }
.focusProductImage{
        width: 100%;  //whaat?
        height: 23vw;
        background-repeat: no-repeat;
        background-position: center;   
        background-size: contain;
        vertical-align: middle;
       
        // margin-right: 1em;
        margin-top: 1em;
        // max-width: 250px;
        

        //old(before onepage input
        // width: 18em;  //whaat?
        // height: 20vw;
        // float: left;
        // background-repeat: no-repeat;
        // background-size: contain;
        // vertical-align: middle;
        // margin-right: 1em;
        // margin-top: 1em;
        // max-width: 250px;
        // margin-top: 1em;
    }
    .focusProductImage img{
        width: 4em;
    }
.productFocus textarea{
    border-style: solid;
    border-width: 3px;
    border-color: $btnBackOrange;
    margin: auto;
    width: 70%;
    height: 7em;
    display: block;
    background-color: $heibaInputBackground;
    white-space: normal;
    text-align: justify;
    -moz-text-align-last: center; /* Firefox 12+ */
    text-align-last: center;
    text-align:center;

}
.task{
    margin-top: 2.5em;
    margin-left: 1.2em;
    color: $btnBackOrange; 
    font-weight: bold;
}

.focusHolder{
    margin-top: 6em;
}

.focusProductCircle{
    background-color: $btnBackOrange;
    height: 10em;
    width: 10em;
    border-radius: 100%;
    border-color: $heibaDarkBackground;
    border-width: 6px;
    border-style: solid;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    flex-direction: column;
    top: -5.5em;
    left: 1em;
}
.focusProductCircleTextSmall,  .focusProductCircleTextBig{
    color:$heibaWhite;
}
.focusProductCircleTextSmall{
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 16px;
    padding-top: 0px;
    padding-bottom: 0px;
    font-weight: bolder;
}
.focusProductCircleTextBig{
    font-size: 20px;
    margin-top: 2px;
    padding-top: 0px;
    font-weight: bolder;
}
.focusProductTextBoxHolder{
    background-color: yellow;
    width: 100%;
    display: flex;
    align-items: center;
}
.focusProductTextBoxHolder textarea{
    width: 75%;
}
.focusProductTextboxBackground{
    background-color: $heibaInputBackground;
    width: 100%;
    border-radius: $heibaBorderRadius;
    left: -3em;
    position: relative;  
}


