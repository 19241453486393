.profileTop{
    width: 95%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 5%;
}




.profilePicture {
    width: 6em;
    height: 5em;
    background-repeat: no-repeat;
    background-size: contain;
    margin-right: 1em;
    background-position: center;
  }