.videoIframe {
  width: 100%;
  height: 55vw;

}
.manualIframe {
  width: 100%;
  height: 55vw;

}
.manuals li{
  padding-top: 1em;
  text-decoration: none;
  color: $btnBackOrange;   
  font-weight: bold;
  list-style: none;
}
.manuals li:hover{
  color: $heibaDarkGreen;   
}
.manuals a:hover{
  color: $heibaDarkGreen;   
}
.manuals a{
  text-decoration: none;
  color: $btnBackOrange;  
  font-weight: bold;
}
.manuals h1{
  color: $heibaGray; 
}
.questions p{
  font-weight: bold;
  color: $heibaLightGray;  
}
.questions a{
  color: $heibaGray; 
}
.questions textarea{
  margin: auto;
  width: 100%;
  height: 5em;
  background-color: $heibaLightBlue; /* heel licht blauw invul veld  */
  margin-bottom: 1.5em;
  margin-top: 1em;
  margin-right: 1em;
  resize: none;
}

.questions label{
  text-decoration: none;
  color: $heibaGray; /* donker grijs */
  font-weight: bold;
}
.questions button{
  float: right;
  margin-right: 0em;
}
.questions .subject{
  height: 3em;
}

   
